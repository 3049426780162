<div class="ddd-cont" *ngIf="configure; else dashboard">
    <h2>Overall Description</h2>
    <textarea rows="4" cols="50" [placeholder]="'Enter the Overall Description'"
        [(ngModel)]="overallDescription"></textarea>

    <div class="loader-wrapper circular-spinners text-center" *ngIf="loading">
        <i class="pi pi-spin pi-spinner"></i>
    </div>

    <p-tabView (onChange)="onTabChange($event)">
        <p-tabPanel *ngFor="let table of renderData" [header]="table.tableName">
            <p-table *ngIf="!loading" [value]="selectedColumns">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Column</th>
                        <th>Data Type</th>
                        <th>Description</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-column>
                    <tr>
                        <td>{{ column.column }}</td>
                        <td>{{ column.dataType }}</td>
                        <td>
                            <input class="form-control" [placeholder]="'Enter Description'" type="text" pInputText
                                [(ngModel)]="column.description" placeholder="Description">
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>

    <h2 class="mt-2">Prompt</h2>
    <textarea rows="4" cols="50" [placeholder]="'Enter the Prompt'" [(ngModel)]="prompt"></textarea>

    <!-- [disabled]="prompt == '' && overallDescription == ''" -->
    <div class="submit-button">
        <button pButton type="button" class="btn btn-primary" (click)="submit()"
            >Confirm</button>
    </div>
</div>

<ng-template #dashboard>

    <ng-container *ngIf="result['response']">
        <div class="p-fluid" *ngFor="let r of result['response']">
            <div *ngIf="r">
                <h3>{{r['plotTitle']}}</h3>
                <p-chart [type]="r['type']" [data]="r['data']" [options]="r['options']"></p-chart>
            </div>
         
        </div>
      </ng-container>

</ng-template>